export const actionTypes = {
  REQUEST_LIST_PROJECT: 'REQUEST_LIST_PROJECT',
  GET_LIST_PROJECT: 'GET_LIST_PROJECT',
  REQUEST_LIST_TASK: 'REQUEST_LIST_TASK',
  GET_LIST_TASK: 'GET_LIST_TASK',
  RESET_LIST_TASK: 'RESET_LIST_TASK',
  REQUEST_DATA_HEATMAP: 'REQUEST_DATA_HEATMAP',
  GET_DATA_HEATMAP: 'GET_DATA_HEATMAP',
  GET_DATE_CURRENT: 'GET_DATE_CURRENT'
};

export const requestListProject = () => ({
  type: actionTypes.REQUEST_LIST_PROJECT
});

export const getListProject = (data: any) => ({
  type: actionTypes.GET_LIST_PROJECT,
  data
});

export const requestListTask = (params: {
  backlog_id: number;
  pagination_token?: string;
  search?: string;
  filter_by_status: boolean;
  filter_by_user: boolean;
  project_id: number;
  reset?: boolean;
}) => ({
  type: actionTypes.REQUEST_LIST_TASK,
  params
});

export const getListTask = (data: any, reset?: boolean) => ({
  type: actionTypes.GET_LIST_TASK,
  data,
  reset
});

export const resetListTask = () => ({
  type: actionTypes.RESET_LIST_TASK
});

export const requestDataHeatmap = (data: any) => ({
  type: actionTypes.REQUEST_DATA_HEATMAP,
  data
});

export const getDataHeatMap = (data: any) => ({
  type: actionTypes.GET_DATA_HEATMAP,
  data
});

export const getDateCurrent = (data: any) => ({
  type: actionTypes.GET_DATE_CURRENT,
  data
});
