import React, { useState } from 'react';
import {
  EditOutlined,
  CopyOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import Col from 'antd/lib/col';
import Dropdown from 'antd/lib/dropdown';
import List from 'antd/lib/list';
import Menu from 'antd/lib/menu';
import message from 'antd/lib/message';
import Modal from 'antd/lib/modal';
import Row from 'antd/lib/row';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import APP_ROUTES from 'src/common/appRoutes';
import { defaultColorById, handleBreakline } from 'src/common/utils';
import EditForm from 'src/components/Heatmap/form/EditForm';
import { copyHeatmap, deleteHistoryHeatmap } from 'src/components/Heatmap/form/formAction';
import { IinforProject, IresourceProject } from 'src/interface/IHeatmapState';
import { icons } from 'src/static';
import { Label as L, Text as T } from 'src/styled/Component';
import { Flex, Wrapper } from 'src/styled/Layout';
// eslint-disable-next-line import/no-named-as-default

interface Props {
  historyList: IresourceProject[];
  projects: IinforProject[];
  height: number;
}

interface FieldData {
  name: string[];
  value: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

export const handleData = (historyList: IresourceProject[]) => {
  const data = historyList
    .sort((iteml: IresourceProject, itemr: IresourceProject) => {
      const datel = new Date(iteml.date).getTime();
      const dater = new Date(itemr.date).getTime();
      return dater - datel;
    })
    .map((item: IresourceProject) => {
      const newData: {
        hm_id: number;
        id: number;
        date: any;
        hour: number;
        color: string;
        project: string;
        activity: string;
        task_id: number;
        task_name: string;
        backlog_key: string;
        url: string;
      } = {
        date: item.date,
        hour: item.hours,
        id: item.project.id,
        activity: item.content,
        color: item.project.color || defaultColorById(item.project.id),
        project: item.project.name,
        hm_id: item.id,
        task_id: item.task.id,
        task_name: item.task.name,
        backlog_key: item.task.backlog_key,
        url: item.task.url
      };
      return newData;
    });
  return data;
};

const iconStyles = { fontSize: 16, marginRight: 8 };

const HistoryItem: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [pathname] = useState(router.pathname);
  const { historyList, height } = props;
  const data = handleData(historyList);
  const [visible, setVisible] = useState(false);
  const [formData, setFormData] = useState(() => {
    const newData: {
      id: number;
      date: string;
      hour: number;
      color: string;
      project: string;
      activity: string;
      isVisible: boolean;
      hm_id: number;
      task: {
        name: string;
        backlogKey: string;
        id: number;
      };
    } = {
      date: dayjs().format(),
      hour: 1,
      id: 0,
      activity: '',
      color: '#fff',
      project: 'default',
      isVisible: true,
      hm_id: 0,
      task: { name: '', backlogKey: '', id: 0 }
    };
    return newData;
  });

  const menu = (dataMenu) => {
    const confirm = () => {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined style={{ color: '#565656' }} />,
        content: 'Are you sure that you want to delete this heatmap?',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: () => {
          dispatch(deleteHistoryHeatmap({ project_id: dataMenu.hm_id }));
        }
      });
    };

    const onCopy = () => {
      dispatch(
        copyHeatmap({
          date: dataMenu.date,
          hour: dataMenu.hour,
          id: dataMenu.id,
          activity: dataMenu.activity,
          color: dataMenu.color,
          project: dataMenu.project,
          isVisible: dataMenu.isVisible,
          hm_id: dataMenu.hm_id,
          task: {
            name: `${dataMenu.backlog_key ? `${dataMenu.backlog_key} - ` : ''}${
              dataMenu.task_name
            }`,
            backlogKey: dataMenu.backlog_key,
            id: dataMenu.task_id
          }
        })
      );
      message.success('Copied!');
    };
    const onEdit = () => {
      setVisible(true);
      setFormData({
        date: dataMenu.date,
        hour: dataMenu.hour,
        id: dataMenu.id,
        activity: dataMenu.activity,
        color: dataMenu.color,
        project: dataMenu.project,
        isVisible: dataMenu.isVisible,
        hm_id: dataMenu.hm_id,
        task: {
          name: dataMenu.task_name,
          backlogKey: dataMenu.backlog_key,
          id: dataMenu.task_id
        }
      });
    };
    const onDelete = () => {
      confirm();
    };

    return (
      <Menu>
        <Menu.Item
          style={{ padding: 0, margin: '4px 0', borderRadius: 3, backgroundColor: '#fff' }}>
          <L.OptionLabel onClick={onCopy}>
            <Col>
              <CopyOutlined style={{ ...iconStyles }} />
              Copy
            </Col>
          </L.OptionLabel>
          <L.OptionLabel onClick={onEdit}>
            <Col>
              <EditOutlined style={{ ...iconStyles }} />
              Edit
            </Col>
          </L.OptionLabel>
          <L.OptionLabel onClick={onDelete}>
            <Col>
              <DeleteOutlined style={{ ...iconStyles }} />
              Delete
            </Col>
          </L.OptionLabel>
        </Menu.Item>
      </Menu>
    );
  };

  const isSlideData = pathname !== APP_ROUTES.HOME;

  const Title = ({
    data
  }: {
    data: {
      hm_id: number;
      id: number;
      date: any;
      hour: number;
      color: string;
      project: string;
      activity: string;
      task_id: number;
      task_name: string;
      backlog_key: string;
    };
  }) => {
    if (!isSlideData) {
      return (
        <Row align={'middle'} className={'records-history'}>
          <Col span={24} style={{ color: '#8B8B8B' }}>
            {dayjs(data.date).format('MMM DD, YYYY')}
          </Col>
          <Col span={24}>
            <Flex full between itemCenter>
              <T.ProjectTitle color={data.color}>{`[${data.id}] ${data.project}`}</T.ProjectTitle>
              <L.ColorLabel width={75} height={30} color={data.color}>
                {data.hour} hours
              </L.ColorLabel>
            </Flex>
          </Col>
        </Row>
      );
    }

    return (
      <Row align={'middle'}>
        <Col span={12}>
          <Row align={'middle'}>
            <T.ProjectTitle color={data.color}>{`[${data.id}] ${data.project}`}</T.ProjectTitle>
          </Row>
        </Col>
        <Col span={5}>
          <L.ColorLabel width={75} height={30} color={data.color}>
            {data.hour} hours
          </L.ColorLabel>
        </Col>
        <Col span={6} style={{ color: '#8B8B8B' }}>
          {dayjs(data.date).format('MMM DD, YYYY')}
        </Col>
        <Col span={1}>
          <Dropdown
            overlay={menu(data)}
            overlayClassName="history-copy"
            getPopupContainer={(trigger: any) => trigger.parentNode}
            trigger={['click']}>
            <img
              alt="more-icon"
              width={17}
              src={icons.IconMore}
              className="icon-more cursor-pointer"
            />
          </Dropdown>
        </Col>
      </Row>
    );
  };

  const setHeightListItem = (heightListItem: number, isSlideData: boolean) => {
    return isSlideData ? `auto` : `${Number(heightListItem) - 57}px`;
  };

  const onCloseModal = () => {
    setVisible(false);
  };

  const initStateFields = [
    {
      name: ['project'],
      value: formData.project ? formData.project : undefined
    },
    {
      name: ['activity'],
      value: formData.activity
    },
    {
      name: ['task'],
      value: {
        taskName: `${formData.task.backlogKey ? `${formData.task.backlogKey} -` : ''} ${
          formData.task.name
        }`,
        backlogKey: formData.task.backlogKey,
        taskId: formData.task.id
      }
    },
    {
      name: ['hour'],
      value: formData.hour
    },
    {
      name: ['date'],
      value: formData.date
    }
  ];

  const onChangeData = (_newFields: FieldData[]) => {
    return _newFields;
  };

  return (
    <>
      <EditForm
        isVisible={visible}
        onClose={onCloseModal}
        formData={formData}
        fields={initStateFields}
        onChange={debounce(onChangeData, 300)}></EditForm>
      <List
        style={{
          height: setHeightListItem(height, isSlideData),
          overflowY: 'auto',
          minHeight: 200,
          maxHeight: 440,
          marginTop: 15
        }}
        className="custom-scrollbar"
        itemLayout="horizontal"
        dataSource={isSlideData ? data : data.slice(0, 3)}
        renderItem={(item) => (
          <List.Item style={{ border: 'none', padding: 0 }}>
            <Wrapper isHover radius={6} padding={16}>
              <List.Item.Meta
                avatar={null}
                title={<Title data={item}></Title>}
                description={
                  item.url ? (
                    <>
                      <a href={item.url} target="_blank" rel="noreferrer">
                        {`${item.backlog_key ? `${item.backlog_key} ` : ''}`}
                      </a>
                      - {item.task_name}
                    </>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: handleBreakline(
                          `${item.backlog_key ? `${item.backlog_key} -` : ''} ${item.task_name}`
                        )
                      }}
                    />
                  )
                }
              />
              {item.activity}
            </Wrapper>
          </List.Item>
        )}
      />
    </>
  );
};

export default HistoryItem;
