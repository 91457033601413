import React, { useEffect, useRef, useState } from 'react';
import DownOutlined from '@ant-design/icons/DownOutlined';
import Col from 'antd/lib/col';
import Divider from 'antd/lib/divider';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import Message from 'antd/lib/message';
import Modal from 'antd/lib/modal';
import Row from 'antd/lib/row';
import Select from 'antd/lib/select';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import iClock from 'src/assets/images/i_clock.png';
import iEdit from 'src/assets/images/i_edit.png';
import iBriefcase from 'src/assets/images/icon-briefcase.png';
import iHour from 'src/assets/images/icon-hour.png';
import { FORMAT_DAY_MONTH_YEAR } from 'src/common/constants';
import useOutsideClick from 'src/common/useOutsideClick';
import { findProjectRecents } from 'src/common/utils';
import DatePicker from 'src/components/DatePicker';
import { IAppState } from 'src/interface/IAppState';
import { IheatmapState, IinforProject, IFormValuesData } from 'src/interface/IHeatmapState';
import { Button as B, DropDownSearchContainer as D, Label as L } from 'src/styled/Component';
import { Lengend } from 'src/styled/Component/Lengend';
import { Wrapper } from 'src/styled/Layout';
import { editForm, editHistoryHeatmap } from './formAction';
import { FieldData } from './HeatmapForm';

const { Option, OptGroup } = Select;

interface PropsFormData {
  id: number;
  date: any;
  hour: number;
  color: string;
  project: string;
  activity: string;
  isVisible: boolean;
  hm_id: number;
  task: {
    id: number;
    backlogKey: string;
    name: string;
  };
}

interface PropsEditForm {
  onClose(): void;

  isVisible: boolean;
  formData: PropsFormData;

  onChange(allFields: any): void;

  fields: FieldData[];
}

const formTailLayout = {
  wrapperCol: {
    span: 'auto',
    offset: 2
  }
};

const SelectInput = styled(Input)`
  border-radius: 0;
`;

export const EditForm: React.FC<PropsEditForm> = (props) => {
  const { onClose, isVisible, formData, fields } = props;
  const ref = useRef<HTMLHeadingElement>(null);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const dataHeatmap: IheatmapState = useSelector((state: IAppState) => state.heatmapState);

  const color = '#333333';
  const { data = [] } = dataHeatmap;
  const proRecent = findProjectRecents(data) || [];
  const formsNoRecent = data.filter((project: IinforProject) => !proRecent.includes(project)) || [];
  const [activity, setActivity] = useState(formData.activity);
  const [hour, setHour] = useState(formData.hour);
  const [date, setDate] = useState(formData.date);
  const [isDisable, setIsDisable] = useState(true);

  const initValueForm = {
    project: formData.project,
    activity: activity,
    hour: hour,
    date: formData.date,
    task: {
      taskId: formData.task.id,
      backlogKey: formData.task.backlogKey,
      taskName: formData.task.name
    }
  };
  const handleOk = () => {
    onClose();
    form.resetFields();
  };

  const handleCancel = () => {
    onClose();
    form.resetFields();
    setDate(formData.date);
    setIsDisable(true);
  };

  const checkHour = async (rule: any, value: string) => {
    if (!value) {
      throw new Error('Hour field is required! Please try again');
    }
    if (typeof value !== 'number') {
      throw new Error('Hour field should be number! Please try again');
    }
    if (value < 0 || value >= 24) {
      throw new Error('Hour field should be is greater than 0 and less than 24');
    }
  };
  useEffect(() => {
    setDate(formData.date);
  }, [form, formData.date]);

  useEffect(() => {
    form.validateFields(['activity', 'hour']);
    setHour(form.getFieldValue('hour'));
    setActivity(form.getFieldValue('activity'));
  }, [form, activity, hour]);

  useOutsideClick(ref, () => {
    if (formData.isVisible) {
      dispatch(editForm(formData.id + ''));
    }
  });

  const onFinish = () => {
    dispatch(
      editHistoryHeatmap({
        ...form.getFieldsValue(),
        project_id: formData.id,
        date: date,
        hm_id: formData.hm_id
      })
    );
    onClose();
  };

  const onFinishFailed = () => {
    Message.error('Invalid data.');
  };

  const onChange = (data: dayjs.Dayjs | null) => {
    if (data && data.format()) {
      setDate(data.format());
    }
  };

  const disabledDate = (current: dayjs.Dayjs) => {
    return current && current > dayjs().endOf('day');
  };

  const handleChangeValue = (_: Partial<IFormValuesData>, values: IFormValuesData) => {
    if (values.hour === formData.hour && values.activity === formData.activity) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  };

  useEffect(() => {
    if (date !== formData.date) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [date, formData.date]);

  return (
    <>
      <Modal
        visible={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        wrapClassName={'edit-heatmap-modal'}>
        <div id={'edit-form-heatmap'} style={{ width: '100%' }}>
          <Col span={24}>
            <Form
              layout={'vertical'}
              form={form}
              fields={fields}
              initialValues={initValueForm}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={handleChangeValue}>
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <Wrapper>
                    <Row>
                      <Col span={2}>
                        <img className={'icon-clock'} width={16} src={iClock} alt="" />
                      </Col>
                      <Col flex={'auto'}>
                        <DatePicker
                          className={'heatmap-picker'}
                          size={'large'}
                          dropdownClassName={'popup-date-picker'}
                          bordered={false}
                          suffixIcon={<DownOutlined style={{ color: '#333333' }} />}
                          inputReadOnly={true}
                          allowClear={false}
                          showToday={false}
                          format={FORMAT_DAY_MONTH_YEAR}
                          name={'date'}
                          style={{ paddingLeft: 10 }}
                          value={dayjs(new Date(date))}
                          onChange={onChange}
                          disabledDate={disabledDate}
                          getPopupContainer={(trigger: any) => {
                            return trigger.parentNode;
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={2}></Col>
                      <Col span={2}>
                        <L.EditDateLabel></L.EditDateLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={2}>
                        <img className={'icon-more'} width={16} src={iBriefcase} alt="" />
                      </Col>
                      <Col flex={'auto'}>
                        <L.TextLabel require color={color}>
                          Project
                        </L.TextLabel>
                      </Col>
                    </Row>
                    <D.DropDownSearchContainer>
                      <Form.Item
                        {...formTailLayout}
                        name={'project'}
                        rules={[
                          {
                            required: true,
                            message: 'Project field is required! Please try again'
                          }
                        ]}>
                        <Select
                          disabled
                          size={'large'}
                          showSearch
                          placeholder={'Select your working project'}
                          optionFilterProp={'children'}
                          getPopupContainer={(trigger: any) => {
                            return trigger.parentNode;
                          }}>
                          <OptGroup label={'RECENT'}>
                            {proRecent.map(
                              (item) =>
                                item && (
                                  <Option key={item.id} value={item.id}>
                                    <Lengend size={12} color={item.color} />
                                    {`[${item.id}] ${item.name}`}
                                  </Option>
                                )
                            )}
                          </OptGroup>
                          <OptGroup label={<Divider style={{ margin: 0 }} />}>
                            {formsNoRecent.map(
                              (item) =>
                                item && (
                                  <Option key={item.id} value={item.id}>
                                    <Lengend size={12} color={item.color} />
                                    {`[${item.id}] ${item.name}`}
                                  </Option>
                                )
                            )}
                          </OptGroup>
                        </Select>
                      </Form.Item>
                    </D.DropDownSearchContainer>
                    <Row>
                      <Col span={2}>
                        <img className={'icon-more'} width={16} src={iBriefcase} alt="" />
                      </Col>
                      <Col flex={'auto'}>
                        <L.TextLabel require color={color}>
                          Task
                        </L.TextLabel>
                      </Col>
                    </Row>
                    <D.DropDownSearchContainer>
                      <Form.Item
                        {...formTailLayout}
                        name={['task', 'taskName']}
                        rules={[
                          {
                            required: true,
                            message: 'Task field is required! Please try again'
                          }
                        ]}>
                        <SelectInput
                          disabled
                          name={['task', 'taskName']}
                          placeholder="Select your working task"
                        />
                      </Form.Item>
                      <Form.Item name={['task', 'taskId']} hidden>
                        <Input type="hidden" />
                      </Form.Item>
                      <Form.Item name={['task', 'backlogKey']} hidden>
                        <Input type="hidden" />
                      </Form.Item>
                    </D.DropDownSearchContainer>
                    <Row>
                      <Col span={2}>
                        <img className={'icon-more'} width={16} src={iEdit} alt="" />
                      </Col>
                      <Col flex={'auto'}>
                        <L.TextLabel require color={color}>
                          Activity/ Purpose/ Mission
                        </L.TextLabel>
                      </Col>
                    </Row>
                    <Form.Item
                      {...formTailLayout}
                      name={'activity'}
                      rules={[
                        {
                          required: true,
                          message: 'Activity field is required! Please try again'
                        }
                      ]}>
                      <Input.TextArea
                        style={{ fontSize: 16 }}
                        placeholder={'Write your task'}
                        rows={6}
                        name={'activity'}
                      />
                    </Form.Item>
                    <Row>
                      <Col span={2}>
                        <img className={'icon-more'} width={16} src={iHour} alt="" />
                      </Col>
                      <Col flex={'auto'}>
                        <L.TextLabel require color={color}>
                          Hours/ day
                        </L.TextLabel>
                      </Col>
                    </Row>
                    <Form.Item {...formTailLayout} name={'hour'} rules={[{ validator: checkHour }]}>
                      <InputNumber name={'hour'} style={{ width: '100%' }} />
                    </Form.Item>
                  </Wrapper>
                </Col>
                {formData.id + '' !== 'default' && (
                  <>
                    <Col span={24}>
                      <Row justify={'space-between'}>
                        <Col span={11}>
                          <B.LightBtn
                            type={'button'}
                            onClick={handleCancel}
                            style={{ width: '100%' }}
                            w={184}
                            h={40}>
                            Cancel
                          </B.LightBtn>
                        </Col>
                        <Col span={11}>
                          <B.DarkBtn
                            disable={isDisable}
                            disabled={isDisable}
                            type="submit"
                            style={{ width: '100%', transition: 'unset' }}
                            h={40}>
                            Done
                          </B.DarkBtn>
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
              </Row>
            </Form>
          </Col>
        </div>
      </Modal>
    </>
  );
};

export default EditForm;
